const validationRules = [
  { state: 'AL', rules: [/^(?!0)\d{1,8}$/] },
  { state: 'AK', rules: [/^(?!0)\d{1,7}$/] },
  { state: 'AZ', rules: [/^[A-Za-z]\d{8}$/, /^(?!0)\d{9}$/] },
  { state: 'AR', rules: [/^(?!0)\d{4,9}$/] },
  { state: 'CA', rules: [/^[A-Za-z]\d{7}$/] },
  { state: 'CO', rules: [/^(?!0)\d{9}$/, /^[A-Za-z]\d{3,6}$/, /^[A-Za-z]{2}\d{2,5}$/] },
  { state: 'CT', rules: [/^(?!0)\d{9}$/] },
  { state: 'DE', rules: [/^(?!0)\d{1,7}$/] },
  { state: 'DC', rules: [/^(?!0)\d{9}$/, /^(?!0)\d{7}$/] },
  { state: 'FL', rules: [/^[A-Za-z]\d{12}$/] },
  { state: 'GA', rules: [/^(?!0)\d{7,9}$/] },
  { state: 'HI', rules: [/^[A-Za-z]\d{8}$/, /^(?!0)\d{9}$/] },
  { state: 'ID', rules: [/^[A-Za-z]{2}\d{6}[A-Za-z]{1}$/, /^(?!0)\d{9}$/] },
  { state: 'IL', rules: [/^[A-Za-z]\d{11,12}$/] },
  { state: 'IN', rules: [/^[A-Za-z]\d{9}$/, /^(?!0)\d{9,10}$/] },
  { state: 'IA', rules: [/^(?!0)\d{9}$/, /^(?!0)\d{3}[A-Za-z]{2}\d{4}  $/] },
  { state: 'KS', rules: [/^(?!0)\d{9}$/, /^[A-Za-z]\d{8}$/, /^[A-Za-z]\d{1}[A-Za-z]\d{1} [A-Za-z]$/] },
  { state: 'KY', rules: [/^[A-Za-z]\d{8}$/, /^[A-Za-z]\d{9}$/, /^(?!0)\d{9}$/] },
  { state: 'LA', rules: [/^(?!0)\d{1,9}$/] },
  { state: 'ME', rules: [/^(?!0)\d{7}$/, /^(?!0)\d{8}$/, /^(?!0)\d{7}[A-Za-z]{1}$/] },
  { state: 'MD', rules: [/^[A-Za-z]\d{12}$/] },
  { state: 'MA', rules: [/^[A-Za-z]\d{8}$/, /^(?!0)\d{9}$/] },
  { state: 'MI', rules: [/^[A-Za-z]\d{10}$/, /^[A-Za-z]\d{12}$/] },
  { state: 'MN', rules: [/^[A-Za-z]\d{12}$/] },
  { state: 'MS', rules: [/^(?!0)\d{9}$/] },

  {
    state: 'MO',
    rules: [
      /^(?!0)\d{9}$/,
      /^(?!0)\d{9}[A-Za-z]{1}$/,
      /^(?!0)\d{9}[A-Za-z]{2}$/,
      /^(?!0)\d{2}[A-Za-z]{1}\d{6}$/,
      /^[A-Za-z]\d{5,9}$/,
      /^[A-Za-z]\d{6}R$/
    ]
  },

  { state: 'MT', rules: [/^[A-Za-z]\d{8}$/, /^(?!0)\d{9}$/, /^(?!0)\d{13,14}$/] },
  { state: 'NE', rules: [/^[A-Za-z]\d{6,8}$/] },
  { state: 'NV', rules: [/^(?!0)\d{9,10}$/, /^(?!0)\d{12}$/, /^X\d{8}$/] },
  { state: 'NH', rules: [/^(?!0)\d{2}[A-Za-z]{3}\d{5}$/] },
  { state: 'NJ', rules: [/^[A-Za-z]\d{14}$/] },
  { state: 'NM', rules: [/^(?!0)\d{8,9}$/] },
  { state: 'NY', rules: [/^(?!0)\d{16}$/, /^(?!0)\d{8,9}$/, /^[A-Za-z]{8}$/, /^[A-Za-z]\d{7}$/, /^[A-Za-z]\d{18}$/] },
  { state: 'NC', rules: [/^(?!0)\d{1,12}$/] },
  { state: 'ND', rules: [/^(?!0)\d{9}$/, /^[A-Za-z]{3}\d{6}$/] },
  { state: 'OH', rules: [/^(?!0)\d{8}$/, /^[A-Za-z]\d{4,8}$/, /^[A-Za-z]{2}\d{3,7}$/] },
  { state: 'OK', rules: [/^[A-Za-z]\d{9}$/, /^(?!0)\d{9}$/] },
  { state: 'OR', rules: [/^(?!0)\d{1,9}$/] },
  { state: 'PA', rules: [/^(?!0)\d{8}$/] },
  { state: 'RI', rules: [/^[A-Za-z]\d{6}$/, /^(?!0)\d{7}$/] },
  { state: 'SC', rules: [/^(?!0)\d{5,11}$/] },
  { state: 'SD', rules: [/^(?!0)\d{6,10}$/, /^(?!0)\d{12}$/] },
  { state: 'TN', rules: [/^(?!0)\d{7,9}$/] },
  { state: 'TX', rules: [/^(?!0)\d{7,8}$/] },
  { state: 'UT', rules: [/^(?!0)\d{4,10}$/] },
  { state: 'VT', rules: [/^(?!0)\d{8}$/, /^(?!0)\d{7}[A-Za-z]{1}$/] },
  { state: 'VA', rules: [/^(?!0)\d{9}$/, /^[A-Za-z]\d{8,11}$/] },
  { state: 'WA', rules: [/^(?!0)\d{1,7}$/] },
  { state: 'WV', rules: [/^(?!0)\d{7}$/, /^[A-Za-z]{1,2}\d{5,6}$/] },
  { state: 'WI', rules: [/^[A-Za-z]\d{13}$/] },
  { state: 'WY', rules: [/^(?!0)\d{9,10}$/] }
];

export const validLicensePattern = /^[a-zA-Z0-9]+$/;

const validateDriversLicense = (
  state: string,
  value: string
): {
  isValid: boolean;
  validationError: string;
} => {
  const stateToValidate = validationRules.find(stateRule => stateRule.state === state);

  const validatedRules = stateToValidate?.rules.map(rule => {
    const regex = new RegExp(rule);
    return regex.test(value);
  });

  const licenseValidation = validatedRules?.length ? validatedRules.some(item => item === true) : false;

  const validationObj = {
    isValid: licenseValidation,
    validationError: 'Please recheck your license or clear the field to skip.'
  };

  return validationObj;
};

export default validateDriversLicense;
