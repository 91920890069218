const SIRV = {
  CARRIERS: {
    CHUBB: {
      url: 'https://maticinsurance.sirv.com/carriers/black/Chubb-logo-grey.png',
      alt: 'Chubb'
    },
    MERCURY: {
      url: 'https://maticinsurance.sirv.com/carriers/black/mercury-logo-grey.png',
      alt: 'Mercury Insurance'
    },
    PLYMOUTH_ROCK: {
      url: 'https://maticinsurance.sirv.com/carriers/black/plymouth-rock-grey.png',
      alt: 'Plymouth Rock Assurance'
    },
    GRANGE: {
      url: 'https://maticinsurance.sirv.com/carriers/black/grange-grey-longcrop.png',
      alt: 'Grange Insurance'
    },
    AMERICAN_MODERN: {
      url: 'https://maticinsurance.sirv.com/carriers/black/american-modern-grey.png',
      alt: 'American Modern'
    },
    SAGE_SURE: {
      url: 'https://maticinsurance.sirv.com/carriers/black/sage-sure-grey-notag.png',
      alt: 'Saga Sure'
    },
    GUARD: {
      url: 'https://maticinsurance.sirv.com/carriers/black/Berkshire-Hathaway-Guard-logo-black.png',
      alt: 'Berkshire Hathaway GUARD Insurance Companies'
    },
    SAFECO: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/safeco.png',
      alt: 'Safeco Insurance'
    },
    PROGRESSIVE: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/progressive.png',
      alt: 'Progressive'
    },
    STILLWATER: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/stillwater.png',
      alt: 'Stillwater'
    },
    CLEARCOVER: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/clearcover.png?h=39',
      alt: 'Clearcover'
    },
    NATIONWIDE: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/nationwide.png',
      alt: 'Nationwide'
    },
    LEMONADE: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/Lemonade.png',
      alt: 'Lemonade'
    },
    BRANCH: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/branch.png',
      alt: 'Branch'
    },
    TRAVELERS: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/travelers.png',
      alt: 'Travelers'
    },
    FOREMOST: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/foremost.png',
      alt: 'Foremost'
    },
    NATIONAL_GENERAL: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/nationalgeneral.png',
      alt: 'National General'
    },
    TOWERHILL: {
      url: 'https://maticinsurance-cdn.sirv.com/carriers/black/towerhill.png',
      alt: 'Towerhill'
    },
    ALLSTATE: {
      url: 'https://maticinsurance.sirv.com/carriers/black/Allstate-logo-grey.png',
      alt: 'Allstate'
    }
  },
  ICON: {
    AGENT: 'https://maticinsurance-cdn.sirv.com/icons/consumer/agent.png'
  },
  BBB_ACCREDITED_BUSINESS: {
    url: 'https://maticinsurance.sirv.com/customer-experience/bbb-accredited-business.jpeg',
    alt: 'bbb-rating-business'
  },
  CONFETTI: 'https://maticinsurance.sirv.com/customer-experience/confetti.png',
  TEAM_IMG: 'https://maticinsurance.sirv.com/agents/team.png',
  QUOTE_HERO_AGENT_LADY: 'https://maticinsurance.sirv.com/customer-experience/quotes-hero-agent-lady.png',
  PAUSE_ICON: 'https://maticinsurance-cdn.sirv.com/icons/svg/pause.svg',
  PLAY_ICON: 'https://maticinsurance-cdn.sirv.com/icons/svg/play.svg',
  AF_MOBILE_BANNER: 'https://maticinsurance.sirv.com/customer-experience/af-mobile-banner.png',
  AF_DESKTOP_BANNER: 'https://maticinsurance.sirv.com/customer-experience/af-desktop-banner.png',
  SAVE: 'https://maticinsurance-cdn.sirv.com/icons/svg/save.svg',
  ERROR_PAGE_ILLUSTRATION: 'https://maticinsurance-cdn.sirv.com/illustrations/error.png',
  AF_MOBILE_FLORIDA: 'https://maticinsurance.sirv.com/FL-AF-small.png',
  AF_DESKTOP_FLORIDA: 'https://maticinsurance.sirv.com/florida-bg-straight.jpg',
  HOME: 'https://maticinsurance.sirv.com/illustrations/home-rebrand.svg',
  COFFEE: 'https://maticinsurance.sirv.com/illustrations/coffe-rebrand.svg',
  NOTE: 'https://maticinsurance.sirv.com/illustrations/note-rebrand.svg',
  NOTES: 'https://maticinsurance.sirv.com/illustrations/florida-notes.svg',
  BANK: 'https://maticinsurance.sirv.com/illustrations/florida-bank.svg',
  FLORIDA_SIGN: 'https://maticinsurance.sirv.com/illustrations/florida-sign.svg',
  LANDING_HERO_IMAGE: 'https://maticinsurance-cdn.sirv.com/customer-experience/ds-landing-banner-rebrand.jpeg',
  CAR_ON_THE_ROAD: 'https://maticinsurance.sirv.com/illustrations/car.svg',
  INFO_ICON: 'https://maticinsurance.sirv.com/illustrations/info.svg',
  VERTICAL_DIVIDER: 'https://maticinsurance.sirv.com/illustrations/verical-divider.svg',
  PERSON_PORTRAIT_WITH_FIGURES: 'https://maticinsurance.sirv.com/customer-experience/person-portrait-with-figures.png',
  PERSON_PORTRAIT_WITH_FIGURES_SQUARE:
    'https://maticinsurance.sirv.com/customer-experience/person-portrait-with-figures-square.png',
  LOOKUP_BANNER: 'https://maticinsurance.sirv.com/customer-experience/lookup-banner-desktop.png',
  HEART: 'https://maticinsurance.sirv.com/customer-experience/heart.gif',
  SEARCH_CARRIERS: 'https://maticinsurance.sirv.com/customer-experience/search-carriers.gif',
  BLURRED_MOBILE_BACKGROUND: 'https://maticinsurance.sirv.com/customer-experience/blurred-mobile-background.png',
  BLURRED_DESKTOP_BACKGROUND: 'https://maticinsurance.sirv.com/customer-experience/blurred-desktop-background.png',
  AGENT_PORTRAIT: 'https://maticinsurance.sirv.com/customer-experience/agent-portrait.png',
  UP_ARROW_ICON: {
    url: 'https://maticinsurance.sirv.com/customer-experience/icons/up-arrow-icon.png',
    alt: 'Better price'
  },

  DOWN_ARROW_ICON: {
    url: 'https://maticinsurance.sirv.com/customer-experience/icons/down-arrow-icon.png',
    alt: 'Worse price'
  },
  OK_ARROW_ICON: {
    url: 'https://maticinsurance.sirv.com/customer-experience/icons/ok-arrow-icon.png',
    alt: 'Same price'
  },
  CONFETTI_ANIMATION: 'https://maticinsurance.sirv.com/customer-experience/confetti.gif'
};

export default SIRV;
