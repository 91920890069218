import React, { useEffect, useRef, useMemo, useState, useCallback } from 'react';

import { useLocation, useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { useFormContext } from 'react-hook-form';

import FullPageLoader from 'src/shared/components/FullPageLoader/FullPageLoader';
import { DefaultLocation, Flows, Page, Entity } from 'src/interfaces/IPage';
import { Answers } from 'src/interfaces/IAnswer';
import analytics from 'src/utils/analytics';
import SEGMENT from 'src/constants/segment';

import useConfig from 'src/api/config/useConfig';
import { FEATURE_TOGGLES_IDS_ENUM as FT } from 'src/interfaces/experiment.types';
import { shouldBlockPhoneUpdate } from 'src/utils/phone';
import useFeatureToggle from 'src/hooks/useFeatureToggle/useFeatureToggle';
import { useCustomNavigate } from 'src/hooks/useCustomNavigate';
import useDisclosures from 'src/api/disclosures/useDisclosures';
import { QUERY_CACHE_KEYS } from 'src/constants/queryCacheKeys';
import { QuestionsFormPages } from 'src/questionsForm/types/questionsFormPage.types';
import { getVisibleFormPart, scrollToError } from 'src/questionsForm/utils/questionsForm.utils';
import FormPage from 'src/questionsForm/components/FormPage/FormPage';
import { getAge } from 'src/utils/date';
import noop from 'src/utils/noop';
import { useMutateSession } from 'src/api/session';
import { isObjectInstanceOfLocationState, trackIfUserWasRedirectedToHome } from './QuestionFormUtils';
import useQuestions from 'src/api/questions/useQuestions';
import useIdleTimer from 'src/hooks/useIdleManualTimer';
import { getInactivityTime } from 'src/utils/inactivityTime';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import RecaptchaModal from 'src/components/RecaptchaModal/RecaptchaModal';
import recaptchaService from 'src/api/recaptcha/recaptcha.service';
import { AddressAnswer } from 'src/interfaces/IQuestion';
import { validLicensePattern } from 'src/utils/driversLicenseValidation';

const QuestionsForm: React.FC = () => {
  const { flow, gid } = useParams() as DefaultLocation;
  const { search, state, pathname } = useLocation();
  const navigate = useCustomNavigate();
  const { data: config, isLoading: isConfigLoading } = useConfig(gid);
  const { data: disclosuresData } = useDisclosures(gid);
  const [isSessionInactive, setIsSessionInactive] = useState(false);
  const [showUploadPopupWarning, setShowUploadPopupWarning] = useState(false);
  const sessionTimeout = getInactivityTime(config?.lock_timeout);
  const [startTimer, resetTimer] = useIdleTimer(sessionTimeout, setIsSessionInactive);
  const isUserIdentified = useRef(false);
  const isUserIdentifiedAsPolicyholder = useRef(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showRecaptchaModal, setShowRecaptchaModal] = useState(false);

  const isFlorida = flow === Flows.Florida;
  const { data: questions, isLoading: isQuestionsLoading } = useQuestions(gid);
  const queryClient = useQueryClient();
  const pages = questions?.schema?.pages || [];
  const features = useFeatureToggle();

  const inactiveSessionFT = useMemo(() => features?.[FT.INACTIVE_SESSION_FT], [features]);
  const shouldStartInactiveTimeout =
    [Flows.Auto, Flows.AutoBundle, Flows.Home].includes(flow) && inactiveSessionFT?.isEnabled;

  const answers = useMemo(() => ({ ...questions?.answers }), [questions]) as Answers;

  const {
    reset,
    watch,
    formState: { isSubmitSuccessful },
    handleSubmit,
    getValues
  } = useFormContext();

  const visiblePages = getVisibleFormPart<Page>(pages, watch());

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    if (answers.person_is_policyholder_identified && !isUserIdentifiedAsPolicyholder.current) {
      analytics.track(SEGMENT.USER_IDENTIFIED_AS_POLICYHOLDER, gid, flow);
      isUserIdentifiedAsPolicyholder.current = true;
    }
  }, [answers, gid, flow]);

  useEffect(() => {
    if (!isUserIdentified.current && questions?.answers?.person_gid && questions.answers?.person_email) {
      analytics.identify(questions.answers.person_gid, {
        email: analytics.generateTrackEmail(questions.answers.person_gid as string),
        age: questions.answers.person_date_of_birth
          ? getAge(questions.answers.person_date_of_birth as string)
          : undefined
      });
      isUserIdentified.current = true;
    }
  }, [isUserIdentified, questions, gid, flow]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(answers);
    }
  }, [answers, reset, isSubmitSuccessful]);

  useEffect(() => {
    if (isObjectInstanceOfLocationState(state) && pathname) {
      trackIfUserWasRedirectedToHome(state, pathname, gid, flow);
    }
  }, [state, pathname, flow, gid]);

  const visibleSteps = visiblePages?.map(p => p.key_name) || [];
  const currentStep = questions?.step || '';
  const refetchQueriesToUpdateConsentData =
    visibleSteps[visibleSteps.length - 2] === currentStep ? [[QUERY_CACHE_KEYS.DISCLOSURES, gid]] : [];
  const { mutateAsync: mutateSession } = useMutateSession(flow, gid, refetchQueriesToUpdateConsentData);

  const customSubmit = useRef<(token?: string | null) => void>(noop);

  const initialUserContacts = { phone: answers?.person_phone, email: answers?.person_email };
  const isPersonalInfoFilled =
    !!answers?.person_first_name &&
    !!answers?.person_last_name &&
    !!answers?.person_date_of_birth &&
    !!answers?.person_gid;

  const shouldRunTimeOutTimer = shouldStartInactiveTimeout && isPersonalInfoFilled;

  useEffect(() => {
    if (shouldRunTimeOutTimer) {
      startTimer();
    }
  }, [shouldRunTimeOutTimer, startTimer]);

  useEffect(() => {
    if (isSessionInactive) {
      resetTimer();
      window.location.reload();
    }
  }, [isSessionInactive, resetTimer]);

  const trackContactsChanged = (email: string, phone: string) => {
    if (email !== initialUserContacts.email) {
      analytics.track(SEGMENT.EMAIL_CHANGE_ATTEMPTED, gid, flow, {
        resolution: SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.SUCCESS
      });
    }

    if (phone !== initialUserContacts.phone) {
      analytics.track(SEGMENT.PHONE_CHANGE_ATTEMPTED, gid, flow, {
        resolution: SEGMENT.CHANGE_ATTEMPTED_RESOLUTION.SUCCESS
      });
    }
  };
  //TODO think about refactoring with zustand
  const onSchemeUpdateRequest = async (manualStep?: string) => {
    const answers = getValues();

    if (shouldBlockPhoneUpdate(answers.person_phone)) {
      delete answers.person_phone;
    }

    await mutateSession({
      step: manualStep || visibleSteps[1],
      answers: { ...answers },
      quotes_request: false
    });
  };

  const handleReCaptchaVerify = useCallback(
    async (step: string) => {
      if (!executeRecaptcha) {
        return;
      }

      try {
        const token = await executeRecaptcha(step);
        return token;
      } catch (error) {}
    },
    [executeRecaptcha]
  );

  const onSubmit = async (i: number, pageType: QuestionsFormPages, recaptcha_v2_value?: string | null) => {
    setIsSubmitting(true);

    await handleSubmit(async (formData: Answers) => {
      const nextStep = visibleSteps[i + 1];
      const answersData = { ...formData };

      // reset idle time for inactive-session redirect
      shouldRunTimeOutTimer && resetTimer();

      // delete phone key from data if it is the same, to not overwrite on back-end.
      // ticket - https://maticinsurance.atlassian.net/browse/CX-855
      if (shouldBlockPhoneUpdate(answersData.person_phone)) {
        delete answersData.person_phone;
      }

      const driversLicensesToMap = answersData.drivers as Entity[];
      driversLicensesToMap?.forEach((driver, i) => {
        if (!driver.driver_license_number || !validLicensePattern.test(driver.driver_license_number as string)) {
          delete (answersData.drivers as Entity[])[i].driver_license_number;
        }
      });

      if (
        isFlorida &&
        answersData.homeowners_insurance &&
        answersData.homeowners_insurance !== answers.homeowners_insurance
      ) {
        analytics.track(SEGMENT.HOMEOWNERS_INSURANCE_ANSWERED, gid, flow, {
          answer: answersData.homeowners_insurance
        });
      }

      if (
        (answers?.property_address as AddressAnswer) &&
        (answersData?.property_address as AddressAnswer)?.line1 !== (answers?.property_address as AddressAnswer)?.line1
      ) {
        analytics.track(SEGMENT.ADDRESS_INPUT_CHANGED, gid, flow);
      }

      if (pageType === QuestionsFormPages.Consent) {
        disclosuresData?.disclosures?.forEach(disclosure => {
          analytics.track(SEGMENT.DISCLOSURE_ACCEPTED, gid, flow, {
            disclosure_gid: disclosure.gid,
            page: SEGMENT.PAGES_KEY.QUESTIONS
          });
        });
      }

      //ineligible returns "FloridaIneligibilityReasons" from back-end only if state === 'FL' for AF|Home flows
      const { florida_ineligibility_reason: ineligible, flow: flowReturned } = await mutateSession({
        step: nextStep,
        answers: answersData,
        quotes_request: !nextStep,
        recaptcha_token: recaptcha_v2_value
      });

      analytics.track(SEGMENT.PAGE_COMPLETED, gid, flow, { page_key: visibleSteps[i] });

      trackContactsChanged(
        (answersData.person_email ?? answers.person_email) as string,
        (answersData.person_phone ?? answers.person_phone) as string
      );

      handleOnSubmitNavigation(i, ineligible, flowReturned);
    }, scrollToError)();
    setIsSubmitting(false);
  };

  const handleOnSubmitNavigation = (pageIndex: number, ineligible?: string, flowReturned?: string) => {
    const isLastPage = visiblePages.length === pageIndex + 1;
    const isFloridaIneligibleRedirect = flow === Flows.Florida && ineligible && currentStep === 'get_quotes';

    if (isLastPage || isFloridaIneligibleRedirect) {
      handleLastPageNavigation(ineligible);
    }

    if (flowReturned && flowReturned !== flow) {
      queryClient.refetchQueries(['config', gid]);

      flowReturned === Flows.Florida && analytics.track(SEGMENT.HOME_TO_FLORIDA_REDIRECT, gid, flow);
      navigate({ pathname: `/${flowReturned}/${gid}`, search });
    }
  };

  const handleLastPageNavigation = (ineligible?: string) => {
    queryClient.removeQueries(['quotes', flow, gid]);

    if (ineligible) {
      navigate(`/${flow}/${gid}/no-options`, {
        state: {
          userName: answers?.person_first_name,
          contactPhone: config?.partner.agent_phone,
          ineligibleType: ineligible
        }
      });
    } else {
      navigate({ pathname: `/${flow}/${gid}/interstitial`, search });
    }
  };

  const canSubmit = useRef(true);

  const onPageSubmit = (i: number, pageType: QuestionsFormPages) => async () => {
    customSubmit.current = (token?: string | null) => onSubmit(i, pageType, token);

    const nextStep = flow === Flows.Auto ? visibleSteps[i + 1] : visibleSteps[i];
    const allowedToSubmitRecaptcha =
      (flow === Flows.Auto && nextStep === 'vehicles') || [Flows.Home, Flows.Florida, Flows.AutoBundle].includes(flow);

    if (allowedToSubmitRecaptcha && import.meta.env.MODE === 'production') {
      const recaptcha_token = await handleReCaptchaVerify(nextStep);

      if (recaptcha_token) {
        const result = await recaptchaService.checkV3Token(gid, { recaptcha_token, action: nextStep });

        if (result.show_recaptcha_puzzle) {
          return new Promise<void>(async res => {
            setShowRecaptchaModal(true);
            analytics.track(SEGMENT.USER_SAW_THE_CAPTCHA, gid, flow);
            res();
          });
        }
      }
    }

    return new Promise<void>(async res => {
      canSubmit.current ? await onSubmit(i, pageType) : setShowUploadPopupWarning(true);

      res();
    });
  };

  const onRecaptchaModalSubmit = async (token: string) => {
    setShowRecaptchaModal(false);
    analytics.track(SEGMENT.USER_SUCCESSFULLY_COMPLETES_THE_CAPTCHA, gid, flow);
    await customSubmit.current(token);
  };

  const uploadFilesWrapperParams = {
    showUploadPopupWarning,
    setShowUploadPopupWarning,
    canSubmit,
    onSubmit: customSubmit.current
  };

  const renderVisiblePage = (p: Page, i: number) => {
    if (i > visibleSteps.indexOf(currentStep)) {
      return null;
    }

    return (
      <FormPage
        key={i}
        isCurrent={currentStep === p.key_name}
        onPageSubmit={onPageSubmit(i, p.type)}
        {...p}
        triggerSchemaUpdate={onSchemeUpdateRequest}
        uploadFilesWrapperParams={uploadFilesWrapperParams}
        isFormSubmitting={isSubmitting}
      />
    );
  };

  if (isQuestionsLoading || isConfigLoading) {
    return <FullPageLoader />;
  }

  return (
    <>
      {config?.integrations?.recaptcha?.site_key_v2 && (
        <RecaptchaModal
          isOpen={showRecaptchaModal}
          onVisibilityChange={onRecaptchaModalSubmit}
          siteKey={config?.integrations?.recaptcha?.site_key_v2}
        />
      )}
      {visiblePages.map(renderVisiblePage)}
    </>
  );
};

export default React.memo(QuestionsForm);
